import { createAsyncThunk } from "@reduxjs/toolkit";
const core = require("../../lib/core");

export const fetchJobList = createAsyncThunk(
  "evaluationProfile/fetchJobList",
  async (payload, { rejectWithValue }) => {
    try {
      let list = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_ALL_EVALUATIONS + payload,
        1,
      );
      const data = list?.data; // Replace this with the actual response data from the API
      return data;
    } catch (error) {
      // Handle errors and return the error message using rejectWithValue
      return rejectWithValue(error.message);
    }
  },
);
export const fetchJobPostList = createAsyncThunk(
  "jobPostList/fetchJobPostList",
  async (payload, { rejectWithValue }) => {
    try {
      // const list = await core.API(
      //   core.API_METHODS.GET,
      //   core.RECRUITER_API.GET_POSTED_JOBS +
      //     "?status=" +
      //     (payload?.status ?? "active") +
      //     "&page=" +
      //     (payload.page ?? 1),
      //   1,
      // );
      // const data = list?.data ?? {}; // Replace this with the actual response data from the API
      // return data;
      const { status = "active", page = 1, sortField, sortOrder } = payload;

      // Build the API URL with sorting parameters
      const apiUrl =
        core.RECRUITER_API.GET_POSTED_JOBS +
        `?status=${status}&page=${page}` +
        (sortField ? `&sortField=${sortField}&sortOrder=${sortOrder}` : "");

      const list = await core.API(core.API_METHODS.GET, apiUrl, 1);
      const data = list?.data ?? {}; // Handle API response

      return data;
    } catch (error) {
      // Handle errors and return the error message using rejectWithValue
      return rejectWithValue(error.message);
    }
  },
);

export const fetchJobPositionList = createAsyncThunk(
  "jobPositions/fetchJobPositionList",
  async (payload, { rejectWithValue }) => {
    try {
      const list = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_ALL_JOBS_POSITIONS,
        1,
      );
      const data = list?.data ?? []; // Replace this with the actual response data from the API
      return data;
    } catch (error) {
      // Handle errors and return the error message using rejectWithValue
      return rejectWithValue(error.message);
    }
  },
);

export const fetchJobDetails = createAsyncThunk(
  "candidateProfile/fetchJobDetails",
  async (payload, { rejectWithValue }) => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_JOB_DETAILS + payload,
        1,
      );
      const data = json?.data; // Replace this with the actual response data from the API

      return data;
    } catch (error) {
      // Handle errors and return the error message using rejectWithValue
      return rejectWithValue(error.message);
    }
  },
);
// export const fetchCandidatesList = createAsyncThunk(
//   "candidates/fetchCandidateList",
//   async (payload, { rejectWithValue }) => {
//     try {
//       const json = await core.API(
//         core.API_METHODS.GET,
//         core.RECRUITER_API.GET_JOB_MATCHPROFILE_DETAILS + payload,
//         1
//       );
//       const data = json; // Replace this with the actual response data from the API

//       return data;
//     } catch (error) {
//       // Handle errors and return the error message using rejectWithValue
//       return rejectWithValue(error.message);
//     }
//   }
// );
export const fetchCandidatesList = createAsyncThunk(
  "candidates/fetchCandidateList",
  async (payload, { rejectWithValue }) => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_MATCH_PROFILES + payload,
        1,
      );
      const data = json; // Replace this with the actual response data from the API

      return data;
    } catch (error) {
      // Handle errors and return the error message using rejectWithValue
      return rejectWithValue(error.message);
    }
  },
);

export const fetchResumesList = createAsyncThunk(
  "resumes/fetchResumesList",
  async (payload, { rejectWithValue }) => {
    //     GET LIST
    // '/resume/status/:jobId
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_RESUMELIST + payload,
        1,
      );
      const data = json?.data; // Replace this with the actual response data from the API

      return data;
    } catch (error) {
      // Handle errors and return the error message using rejectWithValue
      return rejectWithValue(error.message);
    }
  },
);

export const fetchPipeline = createAsyncThunk(
  "pipeline/fetchPipeline",
  async (payload, { rejectWithValue }) => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_PIPELINE + payload,
        1,
      );

      const data = json?.data ?? []; // Replace this with the actual response data from the API

      return data;
    } catch (error) {
      // Handle errors and return the error message using rejectWithValue
      return rejectWithValue(error.message);
    }
  },
);
export const fetchDescription = createAsyncThunk(
  "description/fetchDescription",
  async (payload, { rejectWithValue }) => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_DESCRIPTION + payload,
        1,
      );
      const data = json?.data; // Replace this with the actual response data from the API

      return data;
    } catch (error) {
      // Handle errors and return the error message using rejectWithValue
      return rejectWithValue(error.message);
    }
  },
);

export const fetchReport = createAsyncThunk(
  "report/fetchReport",
  async ({ jobId, params }, { rejectWithValue }) => {
    const paramtoString = new URLSearchParams(params).toString();
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_ALL_ASSESSMENT_REPORT + jobId + "?" + paramtoString,
        1,
      );
      const data = json?.data; // Replace this with the actual response data from the API

      return data;
    } catch (error) {
      console.error(error);
      // Handle errors and return the error message using rejectWithValue
      return rejectWithValue(error.message);
    }
  },
);

export const fetchAssessment = createAsyncThunk(
  "report/fetchAssessment",
  async (payload, { rejectWithValue }) => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_ASSESSMENT + payload,
        1,
      );
      const data = json?.data; // Replace this with the actual response data from the API

      return data;
    } catch (error) {
      // Handle errors and return the error message using rejectWithValue
      return rejectWithValue(error.message);
    }
  },
);

export const fetchQuestionsFroms3 = createAsyncThunk(
  "candidateProfile/fetchQuestionsFroms3",
  async (payload, { rejectWithValue }) => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_QUESTIONS_FROM_S3 + payload,
        1,
      );
      const data = json?.data; // Replace this with the actual response data from the API
      return data;
    } catch (error) {
      // Handle errors and return the error message using rejectWithValue
      return rejectWithValue(error.message);
    }
  },
);
