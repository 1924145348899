import React, { useEffect, useMemo, useState } from "react";
import ActivityItem from "./ActivityItem";
import { useSelector } from "react-redux";
import { filterIcon } from "../icons/Icons";
import Dropdown from "../../../../layouts/Dropdown";
import { ACTIVITY_TYPE } from "../../../../lib/constants";
import { useDispatch } from "react-redux";
import { fetchCandidateActivity } from "../../../../redux/services";
import { ScaleLoader } from "react-spinners";
const core = require("../../../../lib/core");

const ActivityTab = () => {
  const dispatch = useDispatch();
  const appId = useSelector(
    (state) => state?.jobCandidateMatch?.profile?.applicant_id,
  );

  const jobId = useSelector(
    (state) => state?.jobCandidateMatch?.profile?.matchProfile?.job,
  );

  console.log(jobId);

  const lst = useSelector((state) => state?.jobCandidateMatch?.activity);
  const [filter, setFilter] = useState("all");

  const filterLst = useMemo(() => {
    if (filter === "all") return lst;
    return lst.filter((act) => act.activity_type === filter);
  }, [lst, filter]);

  useEffect(() => {
    appId && getActivityData();
  }, [appId]);

  const getActivityData = async () => {
    try {
      dispatch(fetchCandidateActivity({ appId: appId }));
    } catch (error) {
      console.error(error);
    }
  };
  const filterActivity = (type) => () => {
    setFilter(type);
  };

  return appId ? (
    <section
      id="activity-section"
      data-testid="activity-section"
      className="w-full py-4 px-6 pl-10 grow mb-4 overflow-y-auto md:gap-4 relative"
    >
      {lst?.length > 0 ? (
        <>
          <div className="w-full flex justify-end mb-4">
            <Dropdown
              listAlign={"right"}
              buttonLabel={
                <span className="flex gap-2 items-center">
                  {filterIcon}
                  {filter != "all" && (
                    <span className="text-gray-800 text-sm font-semibold capitalize">
                      {filter}
                    </span>
                  )}
                </span>
              }
              dropdownItems={[
                {
                  label: "All",
                  action: filterActivity("all"),
                },
                {
                  label: "Activity",
                  action: filterActivity(ACTIVITY_TYPE.ACTIVITY),
                },
                { label: "Email", action: filterActivity(ACTIVITY_TYPE.EMAIL) },
                {
                  label: "Sequence",
                  action: filterActivity(ACTIVITY_TYPE.SEQUENCE),
                },
                { label: "Note", action: filterActivity(ACTIVITY_TYPE.NOTE) },
              ]}
              btnProps={{
                "data-testid": "filter-dropdown",
                "data-tooltip-id": "tooltip",
                "data-tooltip-content": "Filter by activity type",
              }}
            />
          </div>
          <div className="w-full border-l-2 border-gray-600">
            {filterLst?.map((act, idx) => (
              <ActivityItem
                data={act}
                key={"activity" + idx}
                data-testid={"activityItem"}
              />
            ))}
          </div>
        </>
      ) : (
        <div className="w-full flex items-center justify-center">
          <h1 className="text-base text-gray-800 font-normal non-italic">
            No activity recorded yet
          </h1>
        </div>
      )}
    </section>
  ) : (
    <section
      id="activity-section"
      data-testid="activity-section"
      className="w-full flex items-center justify-center py-10"
    >
      {" "}
      <ScaleLoader height={20} color="#1369E9" loading={true} />
    </section>
  );
};

export default ActivityTab;
