import React, { useState, useEffect } from "react";
import InitialsLogo from "../../../components/all/InitialsLogo";
import { FcApproval } from "react-icons/fc";
import { IoMdTime } from "react-icons/io";
import { IoSend } from "react-icons/io5";
import { FaBookmark } from "react-icons/fa";
import { FaRegBookmark } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaBusinessTime } from "react-icons/fa6";
import { MdLocationCity } from "react-icons/md";
import { RiCloseLargeLine } from "react-icons/ri";
import { ScaleLoader } from "react-spinners";

import parse from "html-react-parser";
const core = require("../../../lib/core");

const timeDifference = (difference) => {
  if (difference < 1000) {
    return "Just now";
  } else if (difference < 60000) {
    return `${Math.floor(difference / 1000)} seconds ago`;
  } else if (difference < 3600000) {
    return `${Math.floor(difference / 60000)} minutes ago`;
  } else if (difference < 86400000) {
    return `${Math.floor(difference / 3600000)} hours ago`;
  } else if (difference < 2592000000) {
    return `${Math.floor(difference / 86400000)} days ago`;
  } else if (difference < 31536000000) {
    return `${Math.floor(difference / 2592000000)} months ago`;
  } else {
    return `${Math.floor(difference / 31536000000)} years ago`;
  }
};

function parseHtml(html) {
  const parsedHtml = parse(html.split("&lt;").join("<"));
  return parsedHtml;
}

const JobDetailsModal = ({
  selectedJob,
  setSelectedJob,
  similarJob,
  similarLoader,
  otherJobs,
  closeModal,
  bookmarkJob,
  applyJob,
}) => {
  const [jobDescription, setJobDescription] = useState(
    parseHtml(selectedJob?.description) || "",
  );

  const [jobtitle, setJobtitle] = useState([selectedJob?.position]);
  const [company, setCompany] = useState(selectedJob?.company?.name);
  console.log("Job Title  is : ", jobtitle);
  console.log("Company Name is : ", company);
  // setJobDescription(parseHtml(jobDescription));
  useEffect(() => {
    // setJobDetails(selectedJob);

    if (selectedJob?.description) {
      setJobDescription(parseHtml(selectedJob.description));
    }
  }, [selectedJob]);

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50  overflow-y-auto">
      <div className="bg-white w-full max-w-6xl mx-6 my-8  px-4 pb-8 py-2 rounded-lg shadow-lg h-[90vh] flex flex-col  overflow-y-auto">
        <div
          className="flex justify-end text-xl hover:cursor-pointer font-bold my-2 "
          onClick={closeModal}
        >
          <RiCloseLargeLine className="text-gray-800 font-bold" />
        </div>
        {/* Left Section - Job Details */}
        <div className="flex">
          <div className="lg:w-2/3 w-full lg:pl-2 lg:pr-6">
            <div className="flex ">
              <div className="mr-4">
                <InitialsLogo
                  width="80px"
                  height="80px"
                  fontSize="36px"
                  styleClass="rounded-xl border border-[#e1e1ee] row-span-4"
                  str={selectedJob?.company?.name || selectedJob?.position}
                />
              </div>
              <div className="  w-full">
                <div className="flex justify-between">
                  <p className="text-gray-600 text-base font-semibold">
                    {selectedJob?.company?.name}
                  </p>
                  <div className="flex justify-between">
                    {selectedJob?.hiringStatus == "Actively hiring" ? (
                      <div className="flex text-gray-700 font-semibold mr-8">
                        <FcApproval className="text-lg mr-1" /> Actively Hiring
                      </div>
                    ) : null}

                    <p className="flex text-gray-600 font-semibold">
                      <IoMdTime className="text-lg mr-1 text-gray-600" />
                      {timeDifference(
                        new Date() - new Date(selectedJob?.createdAt),
                      )}
                    </p>
                  </div>
                </div>
                <h1 className="text-3xl font-bold text-gray-700 ">
                  {selectedJob?.position}
                </h1>
                <div className="flex items-center mt-2 text-gray-600 font-semibold">
                  {selectedJob?.location?.[0] && (
                    <p className="flex text-gray-600">
                      <FaLocationDot className="mt-[3px] mr-2 text-gray-600" />
                      {typeof selectedJob.location[0] === "string"
                        ? selectedJob.location[0]
                        : selectedJob.location[0]?.name}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-end mb-4">
              <button className="px-6 py-2 bg-blue-800 hover:bg-blue-900 text-white rounded-lg mr-4 font-bold">
                Apply
              </button>
              <button
                className="flex p-2 items-center justify-center border border-blue-800 text-blue-800 rounded-lg"
                onClick={() => {
                  bookmarkJob(selectedJob?.id);
                }}
              >
                <FaRegBookmark className="text-lg text-blue-800 hover:cursor-pointer hover:scale-105 transition-all duration-300" />
              </button>
            </div>

            <div className="flex flex-col text-gray-700">
              <div className="flex justify-between">
                {/* <p className="font-semibold mb-2">Job-Type : {job.jobType}</p> */}
              </div>
              <div className="flex justify-between">
                {/* <p className="font-semibold mb-2">Expected Salary : {job.salary}</p> */}
                {/* <p className="font-semibold mb-2">Experience : {selectedJob?.experience}</p> */}
                <p className="font-semibold mb-2">
                  Workplace : {selectedJob?.workplace}
                </p>
                {selectedJob?.experienceLevel && (
                  <p className="flex font-semibold mb-2">
                    <FaBusinessTime className="mt-[3px] mr-2 text-gray-700" />
                    Experience:{" "}
                    {typeof selectedJob.experienceLevel === "string"
                      ? selectedJob.experienceLevel
                      : selectedJob.experienceLevel.min
                        ? `${selectedJob.experienceLevel.min}+ years`
                        : "Not specified"}
                  </p>
                )}
              </div>
            </div>

            <hr className="mt-2 mb-4 border-2" />

            <div className="mb-4 mt-2">
              <h2 className="text-lg font-semibold text-justify ">
                Description
              </h2>
              <p className="text-justify prose !min-w-full text-sm">
                {jobDescription}
              </p>
            </div>

            <div className="my-8">
              <h2 className="text-lg font-semibold text-justify ">Skills</h2>
              {/* <p className='text-justify prose !min-w-full text-sm'>{jobDescription}</p> */}
              <div className="flex flex-wrap items-center gap-2 mt-3">
                {selectedJob?.criterias
                  ?.flatMap((criteria) => {
                    if (criteria?.keySkills) {
                      // Case where keySkills is present
                      return criteria?.keySkills.map((skill) => skill?.label);
                    } else if (criteria?.skills) {
                      // Case where skills is an array of objects with labels or an array of strings
                      return criteria?.skills.map((skill) =>
                        typeof skill === "string" ? skill : skill?.label,
                      );
                    }
                    return [];
                  })
                  .slice(0, 20)
                  .map((skill) => (
                    <span
                      key={`${skill}-${Date.now()}-${Math.random()}`}
                      className="bg-defaultBg text-gray-700 text-sm font-semibold px-2 py-1 rounded"
                    >
                      {skill}
                    </span>
                  ))}
              </div>
            </div>

            <div className="my-10 flex items-center justify-center">
              <button
                className="w-full py-2 font-bold text-base bg-blue-800 hover:bg-blue-900 text-white transition-all duration-300 rounded-md"
                onClick={(e) => {
                  applyJob(e, selectedJob?.id);
                  closeModal();
                }}
              >
                Apply
              </button>
            </div>
          </div>

          {/* Right Section - Similar Jobs */}
          <div className="lg:w-1/3 w-full lg:px-2  ">
            <h2 className="text-xl font-semibold mb-4 text-gray-700 ">
              Similar Jobs
            </h2>
            {similarLoader ? (
              <>
                <div className="auto-complete-list flex items-center justify-center no-list-style w-[23%]  rounded-lg shadow-menuOptionShadow mt-20 py-5">
                  <ScaleLoader height={14} color="#1369E9" loading={true} />
                </div>
              </>
            ) : (
              <>
                {/* <h2 className="text-xl font-semibold mb-4">Similar Jobs</h2> */}
                {similarJob.map((job, index) => (
                  <div
                    key={index}
                    className="bg-gray-100 p-4 mb-4 rounded-lg shadow-md cursor-pointer hover:scale-105 hover:shadow-lg transition-all duration-300"
                    onClick={() => {
                      setSelectedJob(job);
                    }}
                  >
                    <div className="flex space-x-2">
                      <InitialsLogo
                        width="36px"
                        height="36px"
                        fontSize="18px"
                        styleClass="rounded-sm border border-[#e1e1ee] row-span-4"
                        str={job?.company?.name || job?.position}
                      />
                      <div className="flex flex-col space-y-0">
                        <h3 className="text-lg font-semibold text-gray-700 mb-0">
                          {job?.position}
                        </h3>
                        {job.company.name && (
                          <p className="flex text-gray-700 font-semibold text-base mt-0">
                            {job?.company?.name}{" "}
                          </p>
                        )}
                      </div>
                    </div>
                    {job?.company?.name && (
                      <p className="flex justify-between">
                        {job?.workplace && job?.workplace != "" && (
                          <span
                            className={`flex items-end  ${job?.experienceLevel ? "ml-4 " : " "} justify-end  text-gray-600 text-xs`}
                          >
                            <MdLocationCity className="text-gray-600 mr-1 text-sm mb-[2px]" />{" "}
                            Workplace : {job?.workplace}
                          </span>
                        )}
                      </p>
                    )}
                    <div className="flex flex-col justify-between mt-2">
                      <div>
                        {job?.location?.[0] && (
                          <p className="flex text-gray-600 text-xs">
                            <FaLocationDot className="mt-[3px] mr-2 text-gray-600" />
                            {typeof job.location[0] === "string"
                              ? job.location[0]
                              : job.location[0]?.name}
                          </p>
                        )}
                        <div className="flex">
                          {job?.experienceLevel && (
                            <p className="flex text-gray-600 text-xs">
                              <FaBusinessTime className="mt-[3px] mr-2 text-gray-600" />
                              Experience:{" "}
                              {typeof job.experienceLevel === "string"
                                ? job.experienceLevel
                                : job.experienceLevel.min
                                  ? `${job.experienceLevel.min}+ years`
                                  : "Not specified"}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="flex flex-wrap items-center gap-1 mt-3 space-y-1 text-xs">
                          {job?.criterias
                            ?.flatMap((criteria) => {
                              if (criteria?.keySkills) {
                                // Case where keySkills is present
                                return criteria?.keySkills.map(
                                  (skill) => skill?.label,
                                );
                              } else if (criteria?.skills) {
                                // Case where skills is an array of objects with labels or an array of strings
                                return criteria?.skills.map((skill) =>
                                  typeof skill === "string"
                                    ? skill
                                    : skill?.label,
                                );
                              }
                              return [];
                            })
                            .slice(0, 6)
                            .map((skill) => (
                              <span
                                key={`${skill}-${Date.now()}-${Math.random()}`}
                                className="bg-green-100 text-green-700 py-1 px-2 rounded-md text-xs font-medium mx-1"
                              >
                                {skill}
                              </span>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {/* {selectedJob?.company?.name && (
                  <>

                    <h2 className="text-xl font-semibold mt-6 mb-4">
                      Other Jobs from {selectedJob?.company?.name}
                    </h2>
                    {otherJobs.map((job, index) => (
                      <div
                        key={index}
                        className="bg-gray-100 p-4 mb-4 rounded-lg shadow-md"
                      >
                        <h3 className="text-lg font-semibold">{job.title}</h3>
                        <p className="text-gray-500">{job.location}</p>
                        <div className="flex items-center mt-2">
                          {job.skills.map((skill, index) => (
                            <span
                              key={index}
                              className="bg-green-100 text-green-600 py-1 px-2 rounded-full text-xs mx-1"
                            >
                              {skill}
                            </span>
                          ))}
                        </div>
                      </div>
                    ))}
                  </>
                )} */}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetailsModal;
