import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
const core = require("../../../lib/core");

export default function InstantMeeting({}) {
  const nav = useNavigate();
  const [openForm, setOpenForm] = useState(false);
  const [meetForm, setMeetForm] = useState({});
  function setInput(key, value) {
    setMeetForm({ ...meetForm, [key]: value });
  }
  const createInstantMeeting = async (e) => {
    e.preventDefault();
    let json = await core.API(
      core.API_METHODS.POST,
      core.RECRUITER_API.POST_CREATE_INSTANT_MEETING,
      1,
      meetForm,
    );
    console.log(meetForm, json);
    if (json.data.meetingId) {
      nav("/instant-meet/live/" + json.data.meetingId);
    }
  };

  return (
    <section id="instantMeet" className="z-10 absolute bottom-2 right-2">
      <button
        onClick={() => setOpenForm((prev) => !prev)}
        className="w-14 h-14 relative bg-blue-800 rounded-tl-[32px] rounded-tr-[32px] rounded-bl-[32px] rounded-br-lg shadow border-4 border-white p-3"
      >
        <svg
          className="w-full max-w-full max-h-full"
          xmlns="http://www.w3.org/2000/svg"
          width="186"
          height="185"
          viewBox="0 0 186 185"
          fill="none"
        >
          <path
            d="M24.4979 66.3835L42.1158 76.5134C43.0745 77.0646 44.2921 76.7885 44.9588 75.9063C52.529 65.8903 63.2301 59.2376 74.868 56.5137C76.0083 56.2468 76.7665 55.1444 76.5554 53.9923L72.8787 33.9207C72.6758 32.8324 71.606 32.0946 70.5177 32.3344C52.1098 36.3369 35.2144 47.0349 23.7786 63.377C23.1146 64.373 23.4466 65.7749 24.4979 66.3835Z"
            fill="#FFFFFF"
          />
          <path
            d="M11.1849 100.528L33.8535 97.3182C34.6651 97.2075 35.3291 96.6173 35.5689 95.8426L37.5241 89.1656C37.8007 88.2433 37.3949 87.2473 36.5649 86.7677L18.6735 76.4755C17.5299 75.8115 16.0728 76.3649 15.6485 77.6191L8.89771 97.7977C8.43659 99.2733 9.65396 100.749 11.1849 100.528Z"
            fill="#FFFFFF"
          />
          <path
            d="M142.894 37.593C138.393 33.572 138.024 26.6552 142.045 22.1547L158.646 3.63609C162.667 -0.864449 169.584 -1.23334 174.084 2.78763C178.585 6.8086 178.954 13.7254 174.933 18.2259L158.332 36.7445C154.293 41.2451 147.394 41.6324 142.894 37.593Z"
            fill="#FFFFFF"
          />
          <path
            d="M86.9706 126.443C76.4571 126.443 67.9355 117.922 67.9355 107.408C67.9355 96.8945 76.4571 88.373 86.9706 88.373C97.4842 88.373 106.006 96.8945 106.006 107.408C106.006 117.922 97.4842 126.443 86.9706 126.443Z"
            fill="#FFFFFF"
          />
          <path
            d="M60.5578 155.427C61.0918 154.399 60.705 153.136 59.713 152.538C44.5865 143.418 35.6597 127.764 34.494 111.263C34.4018 110.046 33.332 109.142 32.133 109.308L11.936 112.204C10.8477 112.37 10.0362 113.347 10.1468 114.454C12.3251 138.566 25.7877 161.241 48.3272 174.257C49.3601 174.853 50.6735 174.449 51.2234 173.391L60.5578 155.427Z"
            fill="#FFFFFF"
          />
          <path
            d="M84.5998 33.056L88.3625 53.5298C88.5286 54.4704 89.3401 55.1529 90.2992 55.2267C98.0645 55.7247 105.83 57.9565 113.005 62.0881C124.151 68.5 132.071 78.4201 136.136 89.701C137.235 93.279 139.052 100.329 139.3 106.697C139.512 117.546 136.357 128.614 129.413 138.283C117.467 154.959 96.7768 162.827 77.1976 159.206C76.2501 159.031 75.2883 159.477 74.844 160.332L65.2019 178.889C64.5889 180.069 65.1932 181.518 66.4753 181.87C97.6122 190.411 131.899 178.338 150.459 150.973C159.676 137.369 163.965 122.089 163.852 107.065C163.858 107.062 163.864 107.058 163.87 107.054C163.87 101.572 163.87 92.4185 158.789 80.2689C152.694 64.2196 141.233 50.1055 125.178 40.8766C113.005 33.8676 99.6877 30.566 86.5918 30.6028C85.3376 30.6028 84.3785 31.7833 84.5998 33.056Z"
            fill="#FFFFFF"
          />
        </svg>
      </button>
      {openForm && (
        <div className="right-1 bottom-14 absolute z-20 w-[300px] bg-white rounded-xl shadow">
          <div className="flex-col gap-[18px] inline-flex pt-4 px-2 w-full">
            <div className="absolute w-full top-0 left-0 h-[115px] bg-indigo-900 bg-opacity-10 rounded-tl-xl rounded-tr-xl -z-10" />
            <h3 className="text-slate-800 text-base font-medium">
              Create instant meeting
            </h3>
            <h4 className=" placeholder-gray-700 text-gray-800 text-sm font-normal leading-snug">
              Add our AI bot to the meeting
            </h4>
            <form className="w-full divide-y rounded-lg border border-gray-300">
              <div className="w-full rounded-t-lg">
                <svg
                  className="absolute z-10 left-4 mt-[11px]"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.24805 8C9.97394 8 11.373 6.60089 11.373 4.875C11.373 3.14911 9.97394 1.75 8.24805 1.75C6.52216 1.75 5.12305 3.14911 5.12305 4.875C5.12305 6.60089 6.52216 8 8.24805 8Z"
                    fill="#5F6989"
                  />
                  <path
                    d="M8.24766 9.5625C5.11641 9.5625 2.56641 11.6625 2.56641 14.25C2.56641 14.425 2.70391 14.5625 2.87891 14.5625H13.6164C13.7914 14.5625 13.9289 14.425 13.9289 14.25C13.9289 11.6625 11.3789 9.5625 8.24766 9.5625Z"
                    fill="#5F6989"
                  />
                </svg>
                <input
                  type="text"
                  name="name"
                  placeholder="Candidate name"
                  onChange={(e) => {
                    setInput("name", e.target.value);
                  }}
                  className="w-full px-2.5 py-[10px] pl-8 rounded-t-lg text-gray-800 placeholder-gray-700 text-sm font-normal leading-[18px]"
                />
              </div>
              <div className="w-full">
                <svg
                  className="absolute z-10 left-4 mt-[11px]"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.99805 2.375C2.89348 2.375 1.99805 3.27043 1.99805 4.375V4.7113L6.18944 7.75959C7.41672 8.65215 9.07937 8.65215 10.3066 7.75959L14.498 4.7113V4.375C14.498 3.27043 13.6026 2.375 12.498 2.375H3.99805ZM14.498 5.94779L10.8948 8.56832C9.31689 9.71591 7.1792 9.71591 5.60127 8.56832L1.99805 5.94779V11.625C1.99805 12.7296 2.89348 13.625 3.99805 13.625H12.498C13.6026 13.625 14.498 12.7296 14.498 11.625V5.94779Z"
                    fill="#5F6989"
                  />
                </svg>
                <input
                  type="text"
                  name="email"
                  placeholder="Candidate email"
                  onChange={(e) => {
                    setInput("email", e.target.value);
                  }}
                  className="w-full px-2.5 py-[10px] pl-8  placeholder-gray-700 text-gray-800 text-sm font-normal leading-[18px]"
                />
              </div>
              <div className="w-full rounded-b-lg">
                <svg
                  className="absolute z-10 left-4 mt-[11px]"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.27972 8C5.27972 7.74375 5.49222 7.53125 5.74847 7.53125H7.62347V5.1875C7.62347 4.84375 7.34222 4.5625 6.99847 4.5625H5.60472C3.63597 4.5625 1.92347 6.175 1.99847 8.1375C2.03597 9.03125 2.41097 9.8375 3.00472 10.4313C3.62972 11.05 4.48597 11.4375 5.43597 11.4375H6.99847C7.34222 11.4375 7.62347 11.1562 7.62347 10.8125V8.46875H5.74847C5.49222 8.46875 5.27972 8.25625 5.27972 8Z"
                    fill="#5F6989"
                  />
                  <path
                    d="M13.4918 5.56875C12.8668 4.95 12.0105 4.5625 11.0605 4.5625H9.49805C9.1543 4.5625 8.87305 4.84375 8.87305 5.1875V7.53125H10.748C11.0043 7.53125 11.2168 7.74375 11.2168 8C11.2168 8.25625 11.0043 8.46875 10.748 8.46875H8.87305V10.8125C8.87305 11.1562 9.1543 11.4375 9.49805 11.4375H10.8918C12.8605 11.4375 14.573 9.825 14.4918 7.8625C14.4605 6.96875 14.0793 6.1625 13.4918 5.56875Z"
                    fill="#5F6989"
                  />
                  <mask id="path-3-inside-1_3933_28866" fill="white">
                    <path d="M8.87305 7.53125H7.62305V8.46875H8.87305V7.53125Z" />
                  </mask>
                  <path
                    d="M8.87305 7.53125H7.62305V8.46875H8.87305V7.53125Z"
                    fill="#5F6989"
                  />
                  <path
                    d="M7.62305 7.53125V6.53125H6.62305V7.53125H7.62305ZM8.87305 7.53125H9.87305V6.53125H8.87305V7.53125ZM8.87305 8.46875V9.46875H9.87305V8.46875H8.87305ZM7.62305 8.46875H6.62305V9.46875H7.62305V8.46875ZM7.62305 8.53125H8.87305V6.53125H7.62305V8.53125ZM7.87305 7.53125V8.46875H9.87305V7.53125H7.87305ZM8.87305 7.46875H7.62305V9.46875H8.87305V7.46875ZM8.62305 8.46875V7.53125H6.62305V8.46875H8.62305Z"
                    fill="#5F6989"
                    mask="url(#path-3-inside-1_3933_28866)"
                  />
                </svg>
                <input
                  type="text"
                  name="link"
                  placeholder="Meeting link"
                  onChange={(e) => {
                    setInput("link", e.target.value);
                  }}
                  className="w-full px-2.5 py-[10px] pl-8 rounded-b-lg  placeholder-gray-700 text-gray-800 text-sm font-normal leading-[18px]"
                />
              </div>
            </form>
            <button
              onClick={createInstantMeeting}
              className="w-[270px] h-8 px-3.5 py-1 self-center btn-sec justify-center items-center gap-2 inline-flex group [&:hover_path]:stroke-blue-900"
            >
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.85203 13.6095H4.6387C2.53203 13.6095 1.83203 12.2095 1.83203 10.8028V5.18948C1.83203 3.08281 2.53203 2.38281 4.6387 2.38281H8.85203C10.9587 2.38281 11.6587 3.08281 11.6587 5.18948V10.8028C11.6587 12.9095 10.952 13.6095 8.85203 13.6095Z"
                  stroke="#252E4B"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.5125 11.4001L11.6592 10.1001V5.89342L13.5125 4.59342C14.4192 3.96009 15.1658 4.34675 15.1658 5.46009V10.5401C15.1658 11.6534 14.4192 12.0401 13.5125 11.4001Z"
                  stroke="#252E4B"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="text-center text-gray-800 group-hover:text-blue-900 text-sm font-normal leading-normal">
                Create now
              </span>
            </button>
          </div>
          <Link
            to={`/instant-meet`}
            className="mt-6 text-center py-2 flex rounded-bl-xl rounded-br-xl border-t group !no-underline [&:hover_path]:stroke-blue-900 border-slate-200 justify-center items-center gap-1.5 w-full"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.16667 5.83333H14.1667M9.16667 14.1667H14.1667M9.16667 10H17.5M3.33333 8.33333H5C5.46024 8.33333 5.83333 7.96024 5.83333 7.5V5.83333C5.83333 5.3731 5.46024 5 5 5H3.33333C2.8731 5 2.5 5.3731 2.5 5.83333V7.5C2.5 7.96024 2.8731 8.33333 3.33333 8.33333ZM3.33333 15H5C5.46024 15 5.83333 14.6269 5.83333 14.1667V12.5C5.83333 12.0398 5.46024 11.6667 5 11.6667H3.33333C2.8731 11.6667 2.5 12.0398 2.5 12.5V14.1667C2.5 14.6269 2.8731 15 3.33333 15Z"
                stroke="#252E4B"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
            <p className="text-center text-slate-800 text-sm font-normal leading-normal group-hover:text-blue-900">
              View all
            </p>
          </Link>
        </div>
      )}
    </section>
  );
}
