import React, { useEffect, useState } from "react";
import { ICONS } from "./../../../assets/componentAssets/index";
import { useParams } from "react-router";
const core = require("../../../../lib/core");
import { FiSend } from "react-icons/fi";
import { Tooltip } from "antd";
import { MdOutlineEmail } from "react-icons/md";
import { FaRegEnvelopeOpen } from "react-icons/fa";
import { IoIosArrowRoundBack, IoIosTimer } from "react-icons/io";
import { LiaMailBulkSolid } from "react-icons/lia";
import { TbMailExclamation, TbMailPause } from "react-icons/tb";
import PieChart from "../../../charts/PieChart";
import { LuUsers } from "react-icons/lu";
import Skeleton from "react-loading-skeleton";
import { CiViewTable } from "react-icons/ci";
import { LuLayoutDashboard } from "react-icons/lu";
import moment from "moment";
import { toast } from "react-toastify";

const Status = () => {
  const sequenceId = useParams().id;
  const [logs, setLogs] = useState([]);
  const [selectedLog, setSelectedLog] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [jobLogs, setJobLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showView, setShowView] = useState("overview");
  const [chartData, setChartData] = useState({
    MailSent: 0,
    MailReached: 0,
    MailRead: 0,
    PendingMails: 0,
    Total: 0,
    totalCadidates: 0,
    BouncedMails: 0,
  });

  useEffect(() => {
    getSequenceLogs();
  }, []);

  const calculateOverView = (array) => {
    let emailSent = 0,
      emailReached = 0,
      emailRead = 0,
      totalMails = 0,
      pendingMail = 0,
      bouncedMail = 0;
    let candidateSet = new Set();
    for (const i of array) {
      candidateSet.add(i?.candidate_id);
      for (const step of i?.steps) {
        if (step?.action == "WAIT") continue;
        totalMails++;
        if (step?.is_excecuted) {
          emailSent++;
        } else if (!step?.is_excecuted) {
          pendingMail++;
        }
        if (step?.is_excecuted && step?.is_bounced) {
          bouncedMail++;
        }
        if (step?.is_excecuted && !step?.is_bounced) {
          emailReached++;
        }
        if (step?.is_read) emailRead++;
      }
    }
    let totalCadidates = candidateSet.size;
    setChartData({
      MailSent: emailSent,
      MailReached: emailReached,
      MailRead: emailRead,
      PendingMails: pendingMail,
      Total: totalMails,
      totalCadidates: totalCadidates,
      BouncedMails: bouncedMail,
    });
  };

  const getSequenceLogs = async (filter_with_jobs) => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.SEQUENCE_API.GET_SEQUENCE_LOGS + sequenceId,
      1,
    );
    if (json?.response) {
      setLogs(json?.response);
      if (filter_with_jobs && selectedJob) {
        const thisJobLogs = json?.response.filter(
          (log) => log?.job_id == selectedJob?._id,
        );
        setJobLogs(thisJobLogs);
      }
    }
  };

  const getSequenceLogJobs = async () => {
    setLoading(true);
    const json = await core.API(
      core.API_METHODS.GET,
      core.SEQUENCE_API.GET_SEQUENCE_JOBS_BY_ID + sequenceId,
      1,
    );
    if (json) {
      setJobs(json?.data);
    }
    setLoading(false);
  };

  const handleSuspendeSequence = async (id) => {
    if (!id) return;
    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.SEQUENCE_API.SUSPEND_SEQUENCE + id,
        1,
      );
      if (json?.message) {
        toast.success("Sequence suspended successfully");
        getSequenceLogs(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (sequenceId) getSequenceLogJobs();
  }, [sequenceId]);

  return (
    <div className="w-full grow relative rounded-lg bg-white flex flex-col gap-4">
      {loading ? (
        <div className="flex flex-col gap-4 p-5">
          <Skeleton height={100} className="w-full" />
          <Skeleton height={100} className="w-full" />
        </div>
      ) : (
        <>
          {!selectedJob ? (
            <div className="flex flex-col p-3 gap-3">
              {jobs?.length > 0 ? (
                jobs.map((job, i) => (
                  <div
                    key={i}
                    className="p-6 px-6 w-full border-b shadow border rounded-md hover:bg-gray-100"
                  >
                    <div className="flex justify-between">
                      <div>
                        <div>{job?.name}</div>
                        <p>
                          Total Logs:{" "}
                          {logs.filter((log) => log?.job_id == job?._id).length}
                        </p>
                      </div>
                      <div className="flex gap-3 items-center">
                        <button
                          onClick={() => {
                            setSelectedJob(job);
                            setShowView("overview");
                            calculateOverView(
                              logs.filter((log) => log?.job_id == job?._id),
                            );
                          }}
                          className="border-[20px] rounded-md h-10 w-10 flex items-center justify-center text-black drop-shadow-sm hover:drop-shadow-md"
                        >
                          <LuLayoutDashboard className="h-5 w-5 min-w-[20px] min-h-[20px]" />
                        </button>
                        <button
                          onClick={() => {
                            setSelectedJob(job);
                            setJobLogs(
                              logs.filter((log) => log?.job_id == job?._id),
                            );
                            setShowView("table");
                          }}
                          className="border-[20px] rounded-md h-10 w-10 flex items-center justify-center text-black drop-shadow-sm hover:drop-shadow-md"
                        >
                          <CiViewTable className="h-5 w-5 min-w-[20px] min-h-[20px]" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="w-full h-[150px] flex justify-center items-center">
                  <h4 className="text-gray-500">
                    No Jobs Found associated with this sequence
                  </h4>
                </div>
              )}
            </div>
          ) : (
            <>
              <div className="flex gap-3 items-center pt-5">
                <span
                  className="cursor-pointer p-2"
                  onClick={() => {
                    setSelectedJob(null);
                    setSelectedLog(null);
                    setJobLogs([]);
                  }}
                >
                  <IoIosArrowRoundBack className="h-8 w-8" />
                </span>
                <h4 className="font-semibold">
                  {showView == "overview" ? "OVERVIEW" : "All Candidates"} -{" "}
                  {selectedJob?.name}
                </h4>
              </div>
              {showView === "overview" ? (
                <div className="p-5">
                  <div className="py-5 grid grid-cols-4 mx-auto gap-4 bg-white">
                    <div className="flex items-center gap-2 p-4 rounded-md  text-black border shadow-lg">
                      <LuUsers className="w-6 h-6" />
                      <div className="flex flex-col gap-1">
                        <h4>Total Candidates</h4>
                        <p className="text-gray-800">
                          {chartData.totalCadidates}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 p-4 rounded-md  text-black border shadow-lg">
                      <LiaMailBulkSolid className="w-6 h-6" />
                      <div className="flex flex-col gap-1">
                        <h4>Total Mails</h4>
                        <p className="text-gray-800">{chartData.Total}</p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 p-4 rounded-md  text-black border shadow-lg">
                      <FiSend className="w-6 h-6" />
                      <div className="flex flex-col gap-1">
                        <h4>Sent Mails</h4>
                        <p className="text-gray-800">{chartData.MailSent}</p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 p-4 rounded-md  text-black border shadow-lg">
                      <MdOutlineEmail className="w-6 h-6" />
                      <div className="flex flex-col gap-1">
                        <h4>Reached Mails</h4>
                        <p className="text-gray-800">{chartData.MailSent}</p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 p-4 rounded-md  text-black border shadow-lg">
                      <TbMailPause className="w-6 h-6" />
                      <div className="flex flex-col gap-1">
                        <h4>Pending Mails</h4>
                        <p className="text-gray-800">
                          {chartData.PendingMails}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 p-4 rounded-md  text-black border shadow-lg">
                      <FaRegEnvelopeOpen className="w-6 h-6" />
                      <div className="flex flex-col gap-1">
                        <h4>Read Mails</h4>
                        <p className="text-gray-800">{chartData.MailRead}</p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 p-4 rounded-md  text-black border shadow-lg">
                      <TbMailExclamation className="w-6 h-6" />
                      <div className="flex flex-col gap-1">
                        <h4>Bounced Mails</h4>
                        <p className="text-gray-800">
                          {chartData.BouncedMails}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="relative overflow-x-auto">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-300">
                      <tr>
                        {[
                          "Candidate",
                          "Job",
                          "Steps",
                          "Email Steps",
                          "Status",
                          "",
                        ].map((heading) => (
                          <th scope="col" className="px-6 py-5" key={heading}>
                            {heading}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {jobLogs.map((log, i) => (
                        <>
                          <tr className="bg-white border-b text-gray-800 border-[2px]">
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                            >
                              {log?.candidate?.first_name +
                                " " +
                                (log?.candidate?.last_name ?? "")}
                            </th>
                            <td className="px-6 py-4">{log?.job_name}</td>
                            <td className="px-6 py-4">{log?.steps?.length}</td>
                            <td className="px-6 py-4">
                              {
                                log?.steps?.filter(
                                  (step) => step?.action !== "WAIT",
                                )?.length
                              }
                            </td>
                            <td className="px-2 py-1">
                              <div className="flex items-center gap-2">
                                <span
                                  className={`ml-2 p-2 rounded-md text-xs capitalize ${log?.status == "pending" ? "text-orange-800 border-orange-800 border bg-orange-100" : log?.status == "responded" ? "text-blue-800 border-blue-800 border bg-blue-100" : log?.status == "suspended" ? "text-red-800 border-red-800 border bg-red-100" : "text-green-800 border-green-800 border bg-green-100"}`}
                                >
                                  {log?.status}
                                </span>
                                {log?.steps?.some(
                                  (step) =>
                                    step?.is_excecuted && step?.is_bounced,
                                ) && (
                                  <span className="p-2 rounded-md text-xs text-red-800 border-red-800 border bg-red-100">
                                    Bounced
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="px-6 py-4">
                              <span
                                className="cursor-pointer flex items-center ml-auto"
                                onClick={() => {
                                  setSelectedLog(selectedLog === i ? null : i);
                                }}
                              >
                                {selectedLog === i ? (
                                  <ICONS.ChevronUp />
                                ) : (
                                  <ICONS.ChevronDown />
                                )}
                              </span>
                            </td>
                          </tr>
                          {selectedLog === i && (
                            <tr className="bg-white border-b text-gray-800">
                              <td colSpan={6} className="px-6 py-6">
                                <div className="p-4 flex flex-col gap-7 w-full ">
                                  {jobLogs[selectedLog]?.steps?.map(
                                    (step, index) => {
                                      console.log(jobLogs[selectedLog]);
                                      if (step?.action == "WAIT")
                                        return (
                                          <div className="flex flex-col gap-2">
                                            <h5>
                                              <span className="font-bold">
                                                Step {index + 1}:{" "}
                                              </span>
                                              Wait Step:{" "}
                                              <>
                                                {step?.wait_type ==
                                                "Particular Date" ? (
                                                  moment(
                                                    step?.execute_on,
                                                  ).format(
                                                    "DD MMM YYYY hh:mm A",
                                                  )
                                                ) : (
                                                  <>
                                                    {step?.wait_time}{" "}
                                                    {step?.wait_time == 1
                                                      ? "day"
                                                      : "day's"}
                                                  </>
                                                )}
                                              </>
                                            </h5>
                                            <Tooltip title="Wait Time">
                                              <div
                                                className={`h-10 w-10 rounded-md border border-black flex items-center justify-center ${step?.is_excecuted ? "bg-green-100 text-green-800 border-green-800" : logs[selectedLog]?.steps[index - 1]?.is_excecuted ? "bg-orange-100 text-orange-800 border-orange-800" : ""}`}
                                              >
                                                <IoIosTimer />
                                              </div>
                                            </Tooltip>
                                          </div>
                                        );
                                      return (
                                        <div className="flex flex-col gap-2">
                                          <h5>
                                            <span className="font-bold">
                                              Step {index + 1}:{" "}
                                            </span>
                                            {step?.action === "SEND_TEMPLATE"
                                              ? "Send Initial Template"
                                              : "Send Follow Up Mail"}
                                          </h5>
                                          <div className="flex items-center">
                                            <Tooltip
                                              color="white"
                                              overlayInnerStyle={{
                                                padding: "0px",
                                              }}
                                              title={
                                                <div className="p-2 border rounded-sm text-black bg-white">
                                                  {step?.is_excecuted
                                                    ? "Mail Sent"
                                                    : "Mail Not Sent"}
                                                </div>
                                              }
                                            >
                                              <div
                                                className={`h-10 w-10 rounded-md border border-black flex items-center justify-center ${step?.is_excecuted ? "bg-green-100 text-green-800 border-green-800" : ""}`}
                                              >
                                                <FiSend />
                                              </div>
                                            </Tooltip>

                                            <div className="w-[100px] h-0 border-t border-black"></div>

                                            <Tooltip
                                              color="white"
                                              overlayInnerStyle={{
                                                padding: "0px",
                                              }}
                                              title={
                                                <div className="p-2 border rounded-sm text-black bg-white">
                                                  {step?.is_excecuted &&
                                                  step?.is_bounced
                                                    ? "Email Bounced"
                                                    : step?.is_excecuted
                                                      ? "Email Reached"
                                                      : "Email Not Sent"}
                                                </div>
                                              }
                                            >
                                              <div
                                                className={`h-10 w-10 rounded-md border border-black flex items-center justify-center ${step?.is_excecuted && step?.is_bounced ? "bg-red-100 text-red-800 border-red-800" : step?.is_excecuted ? "bg-green-100 text-green-800 border-green-800" : ""}`}
                                              >
                                                {step?.is_excecuted &&
                                                step?.is_bounced ? (
                                                  <TbMailExclamation />
                                                ) : (
                                                  <MdOutlineEmail />
                                                )}
                                              </div>
                                            </Tooltip>

                                            <div className="w-[100px] h-0 border-t border-black"></div>

                                            <Tooltip
                                              color="white"
                                              overlayInnerStyle={{
                                                padding: "0px",
                                              }}
                                              title={
                                                <div className="p-2 border rounded-sm text-black bg-white">
                                                  {step?.is_excecuted &&
                                                  step?.is_read &&
                                                  !step?.is_bounced
                                                    ? "Email Read"
                                                    : "Email Not Read"}
                                                </div>
                                              }
                                            >
                                              <div
                                                className={`h-10 w-10 rounded-md border border-black flex items-center justify-center ${step?.is_excecuted && step?.is_read && !step?.is_bounced ? "bg-green-100 text-green-800 border-green-800" : ""}`}
                                              >
                                                <FaRegEnvelopeOpen />
                                              </div>
                                            </Tooltip>
                                          </div>
                                        </div>
                                      );
                                    },
                                  )}
                                </div>
                                {jobLogs[selectedLog]?.status === "pending" ? (
                                  <div className="flex justify-start px-4 mt-5">
                                    <button
                                      onClick={() => {
                                        handleSuspendeSequence(
                                          jobLogs[selectedLog]?._id,
                                        );
                                      }}
                                      className="px-2 py-1 text-sm border border-red-700 rounded-md text-red-700 hover:bg-red-700 hover:text-white"
                                    >
                                      Suspend this sequence
                                    </button>
                                  </div>
                                ) : jobLogs[selectedLog]?.status ===
                                  "suspended" ? (
                                  <div className="px-4 mt-5">
                                    <span className="p-2 rounded-md text-xs text-red-800 border-red-800 border bg-red-100">
                                      Sequence suspended
                                    </span>
                                  </div>
                                ) : jobLogs[selectedLog]?.status ===
                                    "responded" &&
                                  jobLogs[selectedLog]?.response ? (
                                  <div className="px-4 mt-5">
                                    <p>
                                      <span className="text-green-800">
                                        Responded:
                                      </span>{" "}
                                      {jobLogs[selectedLog]?.response}
                                    </p>
                                  </div>
                                ) : null}
                              </td>
                            </tr>
                          )}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Status;
