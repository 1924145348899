import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { fetchJobDetails } from "../../../../redux/services";
import Modal from "../../../modal/Modal";
import { JobPageContext } from "../../../../lib/context";
// import 'react-beautiful-dnd/style.css';
const core = require("../../../../lib/core");

const formData = {
  name: "",
  duration: 45,
  sequence: ["mcq", "coding", "interview"],
  totalQuestions: 9,
  topics: "Algorithms, Data, General, Models, Types",
  mcqCount: 5,
  mcqScore: 1,
  hasCoding: true,
  hasInterviewQuestions: false,
  hasMCQ: true,
  interviewScore: 0,
  interviewCount: 0,
  codingCount: 2,
  codingScore: 2,
  canGoBack: true,
};

function EditAssessment({ data, edit, jobId, modalDismiss, miniReload }) {
  const { setContentReload } = useContext(JobPageContext);
  const sections = ["MCQ", "Coding", "Interview"];
  const [formDetails, setFormDetails] = useState();
  const [asAdded, setAsAdded] = useState(false);

  function setFormInput(key, value) {
    setFormDetails({ ...formDetails, [key]: value });
  }

  useEffect(() => {
    // setFormDetails(formData)
    autofillData();
  }, [data, edit, jobId]);

  function autofillData() {
    console.log(data);
    if (data && Object?.keys(data)?.length > 0) {
      let form = {
        name: data?.name,
        duration: +data?.duration,
        sequence: data?.sectionOrder,
        totalQuestions: +data?.totalQuestions,
        mcqCount: data?.mcqCount,
        mcqScore: data?.mcqScore,
        hasCoding: data?.hasCoding,
        topics: data?.topics,
        hasInterviewQuestions: data?.hasInterviewQuestions,
        hasMCQ: data?.hasMCQ,
        interviewScore: +data?.interviewScore,
        interviewCount: +data?.interviewCount,
        codingCount: +data?.codingCount,
        codingScore: +data?.codingScore,
        canGoBack: data?.canGoBack,
      };
      console.log(data, form);
      setFormDetails(form);
    } else {
      setFormDetails(formData);
    }
  }

  async function createAssessment() {
    console.log("called", jobId);
    console.log(
      (formDetails?.hasMCQ ? +formDetails?.mcqCount : 0) +
        (formDetails?.hasCoding ? +formDetails?.codingCount : 0) +
        (formDetails?.hasInterviewQuestions ? +formDetails?.interviewCount : 0),
    );
    let json = await core.API(
      core.API_METHODS.POST,
      core.JOB_API.POST_ASSESSMENT,
      1,
      {
        name: formDetails?.name,
        job: jobId,
        duration: formDetails?.duration,
        totalQuestions:
          (formDetails?.hasMCQ ? +formDetails?.mcqCount : 0) +
          (formDetails?.hasCoding ? +formDetails?.codingCount : 0) +
          (formDetails?.hasInterviewQuestions
            ? +formDetails?.interviewCount
            : 0),
        sectionOrder: formDetails?.sequence,
        topics: formDetails?.topics,
        mcqCount: formDetails?.hasMCQ ? +formDetails?.mcqCount : 0,
        codingCount: formDetails?.hasCoding ? +formDetails?.codingCount : 0,
        interviewCount: formDetails?.hasInterviewQuestions
          ? +formDetails?.interviewCount
          : 0,
        hasMCQ: formDetails?.hasMCQ,
        hasCoding: formDetails?.hasCoding,
        hasInterviewQuestions: formDetails?.hasInterviewQuestions,
        mcqScore: formDetails?.mcqScore,
        codingScore: formDetails?.codingScore,
        canGoBack: formDetails?.canGoBack,
      },
    );
    if (json.data) {
      // toast.success(json.data?.message);
      setAsAdded(true);
      // dispatch(fetchJobDetails(jobId + "?status=" + (params?.jobType ?? "active")));
      // reload(prev => prev+1)
      fetchJobDetails();
    } else {
      console.log(json.error);
      toast.error("Something went wrong");
    }
  }

  async function editAssessment() {
    let json = await core.API(
      core.API_METHODS.PUT,
      core.JOB_API.PUT_ASSESSMENT + (data?.id ?? data?._id),
      1,
      {
        name: formDetails?.name,
        duration: formDetails?.duration,
        totalQuestions:
          (formDetails?.hasMCQ ? +formDetails?.mcqCount : 0) +
          (formDetails?.hasCoding ? +formDetails?.codingCount : 0) +
          (formDetails?.hasInterviewQuestions
            ? +formDetails?.interviewCount
            : 0),
        sectionOrder: formDetails?.sequence,
        mcqCount: formDetails?.hasMCQ ? +formDetails?.mcqCount : 0,
        codingCount: formDetails?.hasCoding ? +formDetails?.codingCount : 0,
        interviewCount: formDetails?.hasInterviewQuestions
          ? +formDetails?.interviewCount
          : 0,
        topics: formDetails?.topics,
        hasMCQ: formDetails?.hasMCQ,
        hasCoding: formDetails?.hasCoding,
        hasInterviewQuestions: formDetails?.hasInterviewQuestions,
        mcqScore: Number(formDetails?.mcqScore),
        codingScore: Number(formDetails?.codingScore),
        interviewScore: Number(formDetails?.interviewScore),
        canGoBack: formDetails?.canGoBack,
      },
    );
    if (json.data) {
      toast.success("Assessment updated successfully");
      fetchJobDetails();
      modalDismiss();
    } else {
      console.error(json.error);
      toast.error("Failed to update assessment");
    }
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const updatedSequence = [...formDetails.sequence];
    const [draggedItem] = updatedSequence.splice(source.index, 1);
    updatedSequence.splice(destination.index, 0, draggedItem);

    console.log(updatedSequence);

    setFormInput("sequence", updatedSequence);
  };

  // console.log(formDetails);
  return asAdded ? (
    <Modal
      modalDismiss={modalDismiss}
      defaultButtons={{
        primaryLabel: "OK",
      }}
      dismissOnSave={false}
      onSave={modalDismiss}
      onCancel={modalDismiss}
    >
      <div className="w-[calc(100%-32px)] grid grid-cols-[60px_1fr]  gap-y-2 gap-x-4">
        <svg
          className="row-span-2"
          xmlns="http://www.w3.org/2000/svg"
          width="60"
          height="60"
          viewBox="0 0 72 72"
          fill="none"
        >
          <path
            d="M34.1841 1.38145C35.2573 0.565137 36.7433 0.565137 37.8165 1.38145L42.9371 5.27629C43.5634 5.75265 44.3517 5.96389 45.1323 5.86449L51.5142 5.05174C52.8518 4.88139 54.1387 5.62438 54.66 6.86794L57.1471 12.8012C57.4513 13.5269 58.0284 14.104 58.7541 14.4082L64.6874 16.8954C65.9309 17.4166 66.6739 18.7035 66.5036 20.0411L65.6908 26.423C65.5914 27.2036 65.8027 27.992 66.279 28.6182L70.1739 33.7388C70.9902 34.812 70.9902 36.298 70.1739 37.3712L66.279 42.4918C65.8027 43.118 65.5914 43.9064 65.6908 44.687L66.5036 51.0689C66.6739 52.4065 65.9309 53.6934 64.6874 54.2146L58.7541 56.7018C58.0284 57.006 57.4513 57.5831 57.1471 58.3088L54.66 64.2421C54.1387 65.4856 52.8518 66.2286 51.5142 66.0583L45.1323 65.2455C44.3517 65.1461 43.5634 65.3574 42.9371 65.8337L37.8165 69.7286C36.7433 70.5449 35.2573 70.5449 34.1841 69.7286L29.0636 65.8337C28.4373 65.3574 27.6489 65.1461 26.8684 65.2455L20.4864 66.0583C19.1488 66.2286 17.8619 65.4856 17.3407 64.2421L14.8535 58.3088C14.5494 57.5831 13.9722 57.006 13.2466 56.7018L7.31325 54.2146C6.0697 53.6934 5.32671 52.4065 5.49705 51.0689L6.3098 44.6869C6.4092 43.9064 6.19796 43.118 5.7216 42.4918L1.82676 37.3712C1.01045 36.298 1.01045 34.812 1.82676 33.7388L5.7216 28.6182C6.19796 27.992 6.4092 27.2036 6.3098 26.4231L5.49705 20.0411C5.32671 18.7035 6.0697 17.4166 7.31325 16.8954L13.2466 14.4082C13.9722 14.104 14.5494 13.5269 14.8535 12.8012L17.3407 6.86794C17.8619 5.62438 19.1488 4.88139 20.4864 5.05174L26.8684 5.86449C27.6489 5.96389 28.4373 5.75265 29.0636 5.27629L34.1841 1.38145Z"
            fill="#00AC85"
          />
          <path
            d="M35.9642 37.2984L37.4437 38.8041C37.8356 39.203 38.4784 39.203 38.8703 38.8041L50.1869 27.2862C50.8007 26.6615 51.8076 26.6615 52.4214 27.2862C53.0202 27.8956 53.0202 28.8724 52.4214 29.4818L39.5836 42.548C38.7998 43.3457 37.5142 43.3457 36.7304 42.548L29.3527 35.039C28.7539 34.4296 28.7539 33.4529 29.3527 32.8435C29.9665 32.2187 30.9734 32.2187 31.5872 32.8435L33.7699 35.0651L35.9642 37.2984ZM37.0427 33.9231C36.4458 33.3155 36.4457 32.3416 37.0426 31.7339L42.5434 26.1342C43.1555 25.5111 44.1596 25.511 44.7717 26.1341C45.3687 26.7417 45.3688 27.7156 44.7718 28.3232L39.2711 33.923C38.659 34.5461 37.6549 34.5461 37.0427 33.9231ZM30.4994 40.6697C31.0977 41.2786 31.0977 42.2547 30.4994 42.8636C29.886 43.488 28.8798 43.488 28.2664 42.8636L20.5786 35.039C19.9798 34.4296 19.9803 33.4524 20.5791 32.843C21.1924 32.2188 22.1988 32.2183 22.8121 32.8425L23.8894 33.9389C23.8898 33.9393 23.8898 33.94 23.8894 33.9405C23.8889 33.9409 23.8889 33.9416 23.8894 33.9421L30.4994 40.6697Z"
            fill="white"
            stroke="white"
            strokeWidth="0.2"
          />
        </svg>
        <h1 className="text-black text-base font-medium w-[calc(100%-32px)] not-italic">
          Your assessment was successfully added.
        </h1>
        <span className="text-gray-700 text-sm -mt-3 not-italic font-normal">
          You will see them on assessment list
        </span>
      </div>
    </Modal>
  ) : (
    <Modal
      modalDismiss={modalDismiss}
      className={"md:max-w-[50%]"}
      heading={edit ? "Edit assessment" : "Add new assessment"}
      subheading={"Select the options and create a assessment for candidates"}
      defaultButtons={{
        primaryDisabled: !(
          formDetails?.name?.length > 0 &&
          formDetails?.duration > 0 &&
          (formDetails?.hasMCQ ? +formDetails?.mcqCount : 0) +
            (formDetails?.hasCoding ? +formDetails?.codingCount : 0) +
            (formDetails?.hasInterviewQuestions
              ? +formDetails?.interviewCount
              : 0) >
            0
        ),

        primaryLabel: edit ? "Edit assessment" : "Add assessment",
      }}
      dismissOnSave={false}
      onSave={() => {
        if (edit) editAssessment();
        else createAssessment();
        setAsAdded(true);
        // setContentReload((prev) => prev + 1);
        miniReload();
      }}
      onCancel={modalDismiss}
    >
      <div className="w-full">
        <label
          htmlFor="name"
          className="w-full text-gray-800 text-sm font-medium not-italic after:content-['*'] after:text-red-900 after:ml-1"
        >
          Assessment name
        </label>
        <input
          name="name"
          required
          type="text"
          className="w-full rounded-lg bg-white border border-gray-500 h-10 py-2.5 px-4 active:border active:border-blue active:shadow-inputShadow focus:border-blue focus:shadow-inputShadow"
          value={formDetails?.name}
          onChange={(e) => setFormInput("name", e.target.value)}
        />
      </div>
      <div className="w-full flex flex-row gap-5 mt-4">
        <div className="w-1/2">
          <label
            htmlFor="duration"
            className="w-full text-gray-800 text-sm font-medium not-italic after:content-['*'] after:text-red-900 after:ml-1"
          >
            Total duration (in minutes)
          </label>
          <input
            name={"duration"}
            value={formDetails?.duration}
            required
            className="w-full rounded-lg bg-white border border-gray-500 h-10 py-2.5 px-4 active:border active:border-blue active:shadow-inputShadow focus:border-blue focus:shadow-inputShadow"
            type="number"
            onChange={(e) => setFormInput("duration", e.target.value)}
          />
        </div>
        <div className="w-1/2">
          <label className="w-full text-gray-800 text-sm font-medium not-italic after:content-['*'] after:text-red-900 after:ml-1">
            Total questions
          </label>
          <p className="w-full rounded-lg bg-white border border-gray-500 h-10 py-2.5 px-4 text-sm active:border active:border-blue active:shadow-inputShadow focus:border-blue focus:shadow-inputShadow">
            {(formDetails?.hasMCQ ? +formDetails?.mcqCount : 0) +
              (formDetails?.hasCoding ? +formDetails?.codingCount : 0) +
              (formDetails?.hasInterviewQuestions
                ? +formDetails?.interviewCount
                : 0)}
          </p>
        </div>
      </div>
      <h3 className="text-black text-base font-medium w-full not-italic mt-4">
        Sections
      </h3>
      <h4 className="text-gray-700 text-sm -mt-3 not-italic font-normal mt-1">
        Select or deselect the sections to add or remove, respectively.
      </h4>
      <div className="w-full flex flex-row gap-4 mt-4">
        <h6 className="w-full text-gray-800 text-sm font-medium not-italic">
          Type of sections
        </h6>
        <h6 className="w-5/12 text-gray-800 text-sm font-medium not-italic mb-2">
          No. of questions
        </h6>
        <h6 className="w-5/12 text-gray-800 text-sm font-medium not-italic mb-2">
          Score (per question)
        </h6>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="sections" className="w-full">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="w-full"
            >
              {formDetails?.sequence?.map((section, index) => (
                <Draggable
                  key={section}
                  draggableId={section}
                  index={index}
                  className="w-full"
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={`w-full flex flex-row gap-4 mb-3 last-of-type:mb-0`}
                    >
                      <div className="w-full flex items-center gap-4">
                        <input
                          type="checkbox"
                          className=" !m-0 w-4 h-4"
                          // disabled={section == "interview" ? true : false}
                          checked={
                            section == "mcq"
                              ? formDetails?.hasMCQ
                              : section == "coding"
                                ? formDetails?.hasCoding
                                : formDetails?.hasInterviewQuestions
                          }
                          onChange={(e) => {
                            {
                              let key =
                                section === "mcq"
                                  ? "hasMCQ"
                                  : section == "coding"
                                    ? "hasCoding"
                                    : "hasInterviewQuestions";
                              setFormInput(key, e.target.checked);
                            }
                          }}
                        />
                        <label
                          {...provided.dragHandleProps}
                          data-tooltip="Hold and drag"
                          className="w-full grow !m-0 text-gray-800 text-sm font-medium not-italic"
                        >
                          {section == "mcq"
                            ? "Multiple choice questions"
                            : String(section).charAt(0).toUpperCase() +
                              String(section).slice(1)}
                        </label>
                      </div>
                      <div className="w-5/12">
                        <input
                          // name={section + "QuNo"}
                          className="w-full rounded-lg bg-white border border-gray-500 h-10 py-2.5 px-4 active:border active:border-blue active:shadow-inputShadow focus:border-blue focus:shadow-inputShadow"
                          type="number"
                          min={0}
                          // max={50}
                          disabled={
                            section == "mcq"
                              ? !formDetails?.hasMCQ
                              : section == "coding"
                                ? !formDetails?.hasCoding
                                : !formDetails?.hasInterviewQuestions
                          }
                          value={
                            section == "mcq"
                              ? formDetails?.mcqCount
                              : section == "coding"
                                ? formDetails?.codingCount
                                : formDetails?.interviewCount
                          }
                          onChange={(e) => {
                            section == "mcq"
                              ? setFormInput("mcqCount", +e.target.value)
                              : section == "coding"
                                ? setFormInput("codingCount", +e.target.value)
                                : setFormInput(
                                    "interviewCount",
                                    +e.target.value,
                                  );
                          }}
                        />
                      </div>
                      <div className="w-5/12 ">
                        <input
                          name={section + "Score"}
                          className="w-full rounded-lg bg-white border border-gray-500 h-10 py-2.5 px-4 active:border active:border-blue active:shadow-inputShadow focus:border-blue focus:shadow-inputShadow"
                          min={1}
                          type="number"
                          disabled={
                            section == "mcq"
                              ? !formDetails?.hasMCQ
                              : section == "coding"
                                ? !formDetails?.hasCoding
                                : !formDetails?.hasInterviewQuestions
                          }
                          value={
                            section == "mcq"
                              ? formDetails?.mcqScore
                              : section == "coding"
                                ? formDetails?.codingScore
                                : formDetails?.interviewScore
                          }
                          onChange={(e) => {
                            section == "mcq"
                              ? setFormInput("mcqScore", e.target.value)
                              : section == "coding"
                                ? setFormInput("codingScore", e.target.value)
                                : setFormInput(
                                    "interviewScore",
                                    e.target.value,
                                  );
                          }}
                        />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* <div>
        <h3 className="mt-3 text-black text-base font-medium w-full not-italic">
          Configurations
        </h3>
        <div className="py-2 | flex justify-between items-center ">
          Allow candidates to go back to previous questions
          <Switch
            checked={formDetails?.canGoBack}
            onChange={(e) => {
              console.log(e.target.checked);
              setFormInput("canGoBack", e.target.checked);
            }}
          />
        </div>
      </div> */}
    </Modal>
  );
}

export default EditAssessment;
