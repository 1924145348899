import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "../../modal/Modal";
import InputBox from "../../../layouts/InputBox";
import { convertFromHTML, ContentState, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import { useSelector } from "react-redux";
import Dropdown from "../../../layouts/Dropdown";
import TemplateCRUD from "../sequencing/TemplateListCRUD";
import moment from "moment/moment";
const core = require("../../../lib/core");

const SequencingModal = ({
  data,
  modalDismiss,
  changeModal,
  candidates,
  matchProfileIds,
  updateBulkAvailStatus,
}) => {
  const { user } = useContext(core.UserContext);
  const [edit, setEdit] = useState(-1);
  const [steps, setSteps] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [message, setMessage] = useState({});
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState(() => EditorState.createEmpty());
  const [stepsUpdated, setStepsUpdated] = useState(false);
  const [fromEmail, setFromEmail] = useState(
    user?.userIntegrations?.email ?? "",
  );
  const { jobId, jobName } = useSelector((state) => {
    return {
      jobId: state?.JobExpandSlice?.profile?.id,
      jobName: state?.JobExpandSlice?.profile?.position,
    };
  });

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.SEQUENCE_API.GET_SEQUENCE_DATA + data?._id,
      1,
    );
    if (json?.response) setSteps(json?.response?.steps);

    const list = await core.API(
      core.API_METHODS.GET,
      core.TEMPLATE_API.GET_ALL_TEMPLATE + user?.id,
      1,
    );
    if (list?.data) {
      setTemplates(list?.data);
    }
  };

  const submitSequence = async () => {
    try {
      if (edit > -1) {
        setMessage({
          type: "error",
          message:
            "Please save the changes in template before submitting the sequence",
        });
        return;
      }
      if (!user?.userIntegrations?.refresh_token) {
        console.log("no primary email provider found");
        setMessage({
          type: "error",
          message: (
            <p>
              Your account is not connected with any email provider. Please
              connect your account to send emails. Verify from your settings
              page.{" "}
              <Link className="text-blue font-semibold" to="/settings">
                Go to settings.
              </Link>
            </p>
          ),
        });
        return;
      }
      if (stepsUpdated) {
        let tempData = steps?.map((iter) => {
          let { template, isModified, body, subject, ...rest } = iter;
          const obj = { ...rest };
          if (obj?.execute_on) {
            let localDate = moment(obj?.execute_on).tz(moment.tz.guess());
            obj.execute_on = localDate.format();
          }
          return obj;
        });

        let json = await core.API(
          core.API_METHODS.PUT,
          core.SEQUENCE_API.PUT_SEQUENCE_DATA + data?._id,
          1,
          {
            steps: tempData,
          },
        );
        if (json?.message)
          setMessage({ type: "success", message: json?.message });
        if (json?.code && json?.code != 200) {
          setMessage({
            type: "error",
            message:
              json?.message ??
              "Unable to update the wait time. Please try again",
          });
        }
      }
      let json = await core.API(
        core.API_METHODS.POST,
        core.SEQUENCE_API.POST_ADD_SEQUENCE,
        1,
        {
          from_email_address: user?.email,
          from_email: fromEmail || "",
          job_id: jobId,
          org_name: user?.organization?.name,
          job_name: jobName,
          sequence_id: data?._id,
          match_profile_ids: matchProfileIds,
          applicants: candidates,
        },
      );
      if (json?.data) {
        setMessage({
          type: "success",
          message: "Sequence added successfully",
        });
        if (updateBulkAvailStatus) {
          updateBulkAvailStatus("sequence_status", "Pending");
        }
        setTimeout(() => {
          modalDismiss();
        }, 3000);
      } else {
        setMessage({
          type: "error",
          message:
            json?.error?.message ??
            "Unable to add the sequence. Please try again",
        });
      }
    } catch (err) {
      console.error(err);
      setMessage({
        type: "error",
        message: "Unable to add the sequence. Please try again",
      });
    }
  };

  return (
    <Modal
      heading={data?.name}
      modalDismiss={modalDismiss}
      noBtns={true}
      className={"sm:!max-w-[60%] !pb-0"}
    >
      <TemplateCRUD
        fromEmail={fromEmail}
        setFromEmail={setFromEmail}
        showFromMail={true}
        steps={steps}
        setSteps={setSteps}
        templates={templates}
        onSubmit={submitSequence}
        onCancel={() => changeModal("bulk-action-modal")}
        stepsUpdated={stepsUpdated}
        setStepsUpdated={setStepsUpdated}
        btnGrpClass=" sticky -bottom-0 bg-white py-4 border-t-2 border-gray-500 z-50"
        message={message}
        setMessage={setMessage}
        is_candidate_specific={true}
        defaultEdit={true}
      />
    </Modal>
  );
};

export default SequencingModal;
