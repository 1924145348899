// import { useSelector } from "react-redux";
// import { useState } from "react";
// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
// import "@cyntler/react-doc-viewer/dist/index.css";
// import { useEffect } from "react";
// export default function ResumeTab() {
//   const profile = useSelector(
//     (state) => state?.jobCandidateMatch?.profile?.matchProfile?.resume_link,
//   );
//   const cover_letter_link = useSelector(
//     (state) => state?.jobCandidateMatch?.profile?.matchProfile?.cover_letter_link,
//   );

//   const { coverLetter, screeningAnswers } = useSelector((state) => {
//     return {
//       coverLetter:
//         state?.jobCandidateMatch?.profile?.screeningQsnAns?.cover_latter,
//       screeningAnswers:
//         state?.jobCandidateMatch?.profile?.screeningQsnAns
//           ?.screening_questions_answers,
//     };
//   });

//   const [docs, setDocs] = useState([]);

//   useEffect(() => {
//     if (profile) {
//       setDocs([{ uri: profile }]);
//     }
//   }, [profile]);

//   return (
//     <div className="w-full p-2 ">
//       <div className="flex items-center justify-center top-2 ">
//         <DocViewer
//           style={{
//             width: 1000,
//             borderColor: "lightgray",
//             borderRadius: 10,
//             borderWidth: 1,
//             minHeight: 900,
//           }}
//           config={{
//             header: {
//               disableHeader: true,
//               disableFileName: true,
//               retainURLParams: true,
//             },
//             noRenderer: {
//               overrideComponent: () => (
//                 <div className="w-full h-full flex justify-center items-center">
//                   Failed to load
//                 </div>
//               ),
//             },
//             pdfZoom: {
//               defaultZoom: 0.8, // 1 as default,
//             },
//           }}
//           documents={docs}
//           pluginRenderers={DocViewerRenderers}
//         />
//       </div>

//       {/* <iframe className="w-full h-full" title="PDF Viewer" src="https://sproutsai-staging.s3.amazonaws.com/03b96a99-22cf-4abb-9c98-442a843d73aa/file-sample_100kB.doc"/> */}

//       <div>
//         {" "}
//         {screeningAnswers?.length > 0 ? (
//           <div className="flex flex-col mt-2 p-2 bg-white rounded-lg">
//             <h2 className="text-base font-medium text-[#16182f] mb-2">
//               Screening Question Responses
//             </h2>
//             {screeningAnswers.map((question, index) => (
//               <div key={index} className="w-full p-4  rounded-md">
//                 <div className="">
//                   <h2 className="text-base font-medium text-[#16182f]">
//                     {`${index + 1}: ${question?.question?.title}`}
//                   </h2>
//                 </div>
//                 <div>
//                   <span className="text-gray-800 font-normal text-sm">
//                     {`Answer: ${question?.answer ? question?.answer : ""}`}
//                   </span>
//                 </div>
//               </div>
//             ))}
//           </div>
//         ) : null}
//       </div>
//     </div>
//   );
// }

import { useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";

export default function ResumeTab() {
  const profile = useSelector(
    (state) => state?.jobCandidateMatch?.profile?.matchProfile?.resume_link,
  );
  const cover_letter_link = useSelector(
    (state) =>
      state?.jobCandidateMatch?.profile?.matchProfile?.cover_letter_link,
  );
  const cover_letter = useSelector(
    (state) => state?.jobCandidateMatch?.profile?.matchProfile?.cover_letter,
  );
  const all_details = useSelector((state) => state?.jobCandidateMatch?.profile);
  console.log("All Details are : ", all_details);

  const { coverLetter, screeningAnswers } = useSelector((state) => {
    return {
      coverLetter:
        state?.jobCandidateMatch?.profile?.screeningQsnAns?.cover_letter,
      screeningAnswers:
        state?.jobCandidateMatch?.profile?.screeningQsnAns
          ?.screening_questions_answers,
    };
  });

  const [docs, setDocs] = useState([]);
  const [coverLetterDocs, setCoverLetterDocs] = useState([]);
  const [showFullCoverLetter, setShowFullCoverLetter] = useState(false);

  useEffect(() => {
    if (profile) {
      setDocs([{ uri: profile }]);
    }
    if (cover_letter_link) {
      setCoverLetterDocs([{ uri: cover_letter_link }]);
    }
  }, [profile, cover_letter_link]);

  const toggleCoverLetter = () => {
    setShowFullCoverLetter(!showFullCoverLetter);
  };

  const coverLetterPreview = cover_letter
    ? cover_letter.split("\n").slice(0, 5).join(" ") + "..."
    : "";

  setTimeout(() => {
    const style = document.createElement("style");
    style.textContent = `
        #pdf-zoom-in.disabled, #pdf-zoom-out.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      `;
    document.head.appendChild(style);

    const pdfPage = document.querySelector(".react-pdf__Page");
    const zoomInButton = document.getElementById("pdf-zoom-in");
    const zoomOutButton = document.getElementById("pdf-zoom-out");

    if (pdfPage && zoomInButton && zoomOutButton) {
      const checkScaleFactor = () => {
        const style = window.getComputedStyle(pdfPage);
        const scaleFactor = parseFloat(
          style.getPropertyValue("--scale-factor"),
        );

        if (scaleFactor >= 1.5) {
          zoomInButton.disabled = true;
          zoomInButton.classList.add("disabled");
        } else {
          zoomInButton.disabled = false;
          zoomInButton.classList.remove("disabled");
        }

        if (scaleFactor < 0.9) {
          zoomOutButton.disabled = true;
          zoomOutButton.classList.add("disabled");
        } else {
          zoomOutButton.disabled = false;
          zoomOutButton.classList.remove("disabled");
        }
      };

      // Initial check
      checkScaleFactor();

      // Set up a MutationObserver to watch for changes in the style attribute
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "style"
          ) {
            checkScaleFactor();
          }
        });
      });

      observer.observe(pdfPage, {
        attributes: true,
        attributeFilter: ["style"],
      });
    }
  }, 3000);

  return (
    <div className="w-full p-2 ">
      <div className="flex flex-col mt-5 top-2 px-2">
        {/* Resume DocViewer */}
        <h2 className="text-lg text-gray-700 font-semibold xl:ml-8 mb-4">
          Resume
        </h2>
        <div className="flex items-center justify-center">
          <DocViewer
            style={{
              width: 1200,
              borderColor: "lightgray",
              borderRadius: 10,
              borderWidth: 1,
              minHeight: 900,
            }}
            config={{
              header: {
                disableHeader: true,
                disableFileName: true,
                retainURLParams: true,
              },
              noRenderer: {
                overrideComponent: () => (
                  <div className="w-full h-full flex justify-center items-center">
                    Failed to load
                  </div>
                ),
              },
              pdfZoom: {
                defaultZoom: 0.8,
              },
            }}
            documents={docs}
            pluginRenderers={DocViewerRenderers}
          />
        </div>
      </div>

      {/* Cover Letter DocViewer */}
      {coverLetterDocs.length > 0 ? (
        <div className="flex flex-col top-2 px-2 mt-8">
          <h2 className="text-lg text-gray-700 font-semibold xl:ml-8 mb-4">
            Cover Letter
          </h2>
          <div className="flex items-center justify-center">
            <DocViewer
              style={{
                width: 1200,
                borderColor: "lightgray",
                borderRadius: 10,
                borderWidth: 1,
                minHeight: 900,
              }}
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                  retainURLParams: true,
                },
                noRenderer: {
                  overrideComponent: () => (
                    <div className="w-full h-full flex justify-center items-center">
                      Failed to load
                    </div>
                  ),
                },
                pdfZoom: {
                  defaultZoom: 1,
                },
              }}
              documents={coverLetterDocs}
              pluginRenderers={DocViewerRenderers}
            />
          </div>
        </div>
      ) : cover_letter ? (
        <div className="flex flex-col top-2 mt-8 px-2">
          <h2 className="text-lg text-gray-700 font-semibold xl:ml-8 mb-4">
            Cover Letter
          </h2>
          <div className="bg-defaultBg p-4 rounded-md xl:w-[95%] mx-auto  ">
            <p className="text-sm text-gray-700 whitespace-pre-wrap text-justify">
              {showFullCoverLetter ? cover_letter : coverLetterPreview}
            </p>
            <button
              className="text-blue-600 text-sm mt-2"
              onClick={toggleCoverLetter}
            >
              {showFullCoverLetter ? "Read less" : "Read more"}
            </button>
          </div>
        </div>
      ) : null}

      <div>
        {screeningAnswers?.length > 0 ? (
          <div className="flex flex-col top-2 mt-8 px-2  mb-4">
            <h2 className="text-lg text-gray-700 font-semibold xl:ml-8 mb-2">
              Screening Questions
            </h2>
            {screeningAnswers.map((question, index) => (
              <div
                key={index}
                className="bg-defaultBg w-full p-4 my-2 rounded-md xl:w-[95%] mx-auto  "
              >
                <div className="">
                  <h2 className="text-sm font-semibold text-gray-800 ">
                    {question?.question?.question}
                  </h2>
                </div>
                <div className="mt-3">
                  <span className="text-gray-800 font-normal text-sm ">
                    {question?.answer ? question?.answer : ""}
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}
