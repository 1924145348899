import React, { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
var cityTimezones = require("city-timezones");

const TimezoneSelect = ({ Timezone, setTimezone }) => {
  const [timeZones, setTimeZones] = useState([]);
  const [filteredTimeZones, setFilteredTimeZones] = useState([]);
  const [search, setSearch] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const containerRef = useRef(null);
  const listRef = useRef(null);

  useEffect(() => {
    const timezonesData = moment.tz.names().map((tz) => {
      const offset = moment.tz(tz).utcOffset();
      return {
        label: `(UTC ${moment.tz(tz).format("Z")}) ${tz}`,
        value: tz,
        offset: offset,
      };
    });

    // Sort the timezones based on offset
    const sortedTimezonesData = timezonesData.sort(
      (a, b) => a.offset - b.offset,
    );

    setTimeZones(sortedTimezonesData);
    setFilteredTimeZones(sortedTimezonesData);
  }, []);

  useEffect(() => {
    const userTimezone = moment.tz.guess();
    setTimezone(userTimezone);
  }, [setTimezone]);

  const handleSearch = (e) => {
    setFilteredTimeZones(
      timeZones.filter((tz) =>
        tz.label.toLowerCase().includes(search.toLowerCase()),
      ),
    );
    setHighlightedIndex(0); // Reset highlight index on new search
  };

  useEffect(() => {
    if (search !== "") {
      handleSearch(search);
    }
  }, [search]);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setHighlightedIndex((prevIndex) =>
        prevIndex < filteredTimeZones.length - 1 ? prevIndex + 1 : 0,
      );
    } else if (e.key === "ArrowUp") {
      setHighlightedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex,
      );
    } else if (e.key === "Enter" && highlightedIndex >= 0) {
      setTimezone(filteredTimeZones[highlightedIndex].value);
      setIsDropdownOpen(false);
    }

    const list = listRef.current;
    if (list) {
      const item = list.children[highlightedIndex];
      if (item) {
        item.scrollIntoView({
          behavior: "auto",
          block: "nearest",
        });
      }
    }
  };

  const handleSelectTimezone = (timezone) => {
    setTimezone(timezone.value);
    setIsDropdownOpen(false);
  };

  const handleClickOutside = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  const findTimezone = (city) => {
    const cityLookup = cityTimezones.lookupViaCity(city);
    console.log(cityLookup);

    if (cityLookup.length > 0) {
      setSearch(cityLookup?.[0]?.timezone);
      // handleSearch(cityLookup?.[0]?.timezone);
    } else {
      setSearch("");
    }
  };

  return (
    <div ref={containerRef} className="flex flex-col gap-2 w-full">
      <div className="relative">
        <input
          type="text"
          value={`${
            Timezone
              ? `(UTC ${moment.tz(Timezone).format("Z")}) ${Timezone}`
              : `Search Timezone`
          }`}
          readOnly
          className="w-full border border-gray-400 p-2 rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600 pr-10"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          data-tooltip-id="tooltip"
          data-tooltip-content={Timezone}
        />
        <div
          className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>

      {isDropdownOpen && (
        <div className="relative">
          <div className="absolute top-0 z-10 bg-white w-full">
            <input
              type="text"
              placeholder="Search Timezone"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={handleKeyDown}
              className="absolute border border-gray-400 p-2 rounded-lg w-full placeholder:text-[#5F6989]"
            />
            <ul
              ref={listRef}
              className="absolute top-[40px] py-2 bg-white w-full overflow-y-auto max-h-[400px] border border-gray-400 px-2 text-base rounded-lg shadow-xl shadow-[0px_2px_0px_rgba(0,0,0,0.04)]"
            >
              {filteredTimeZones?.length > 0 ? (
                filteredTimeZones.map((tz, index) => (
                  <li
                    key={tz.value}
                    className={`p-2 cursor-pointer ${
                      highlightedIndex === index
                        ? "bg-defaultBg rounded-md"
                        : ""
                    }`}
                    onClick={() => handleSelectTimezone(tz)}
                    onMouseEnter={() => setHighlightedIndex(index)}
                  >
                    {tz.label}
                  </li>
                ))
              ) : (
                <li
                  className="px-2 text-center text-sm text-gray-700 cursor-pointer"
                  onClick={() => findTimezone(search)}
                >
                  Find timezone for {search}
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimezoneSelect;
